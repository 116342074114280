import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Accounts.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const Accounts = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/store/manage-accounts@2x.svg 2x"
              srcSetDark="/images/store/manage-accounts-dark@2x.svg 2x"
              src="/images/store/manage-accounts.svg"
              srcDark="/images/store/manage-accounts-dark.svg"
              alt="manage accounts"
            />
          </div>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInDown"
            offset={300}
          >
            <Image
              srcSet="/images/store/menu@2x.svg 2x"
              srcSetDark="/images/store/menu-dark@2x.svg 2x"
              src="/images/store/menu.svg"
              srcDark="/images/store/menu-dark.svg"
              alt="menu"
            />
          </ScrollParallax>
          
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}><span className={styles.accent}>Multiple</span> accounts</h2>
          <div className={styles.info}>
          Enjoy the flexibility of utilizing multiple accounts and seamlessly switching between them for enhanced privacy and convenience.
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Accounts;
