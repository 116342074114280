import React from "react";
import cn from "classnames";
import styles from "./WhyVelgazm.module.sass";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Explore the Velas Ecosystem",
    content:
      "Seamlessly manage your NFTs (soon) and VRC-20 tokens.",
    img: "ecosystem",
    imgDark: "ecosystem-dark",
  },
  {
    title: "Secure and Reliable Transactions",
    content:
      "Safely swap and oversee your crypto assets.",
    img: "security",
    imgDark: "security-dark",
  },
  {
    title: "Cross-platform",
    content:
      "Getting access to your wallet has never been easy. Access seamlessly from your browser, iOS and/or Android devices.",
    img: "cross-platform",
    imgDark: "cross-platform-dark",
  },
];

const WhyVelgazm = ({ className, scrollToRef }) => {
  return (
    <div className={cn(className, styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h1", styles.title)}>
          <span className={styles.accent}>Why Choose</span> Velgazm Wallet?
          </h2>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <Image className={styles.icon}
              src={"/images/store/" + x.img + ".svg"}
              srcDark={"/images/store/" + x.imgDark + ".svg"}
              alt="icon"
              />
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyVelgazm;
