import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";
import Theme from "../Theme";

const navLinks = [
  {
    title: "Explore",
    to: "whyVelgazm",
  },
  {
    title: "Features",
    to: "features",
  },
  {
    title: "Support",
    url: "https://t.me/velascommunity",
  },
  {
    title: "GitHub",
    url: "https://github.com/velas",
  },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.svg"
            srcDark="/images/logo-light.svg"
            alt="Logo"
          />
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.url ? (
                <a
                  className={styles.link}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  {x.title}
                </a>
              ) : (
                <ScrollLink
                  className={styles.link}
                  activeClassName={styles.active}
                  to={x.to}
                  smooth={true}
                  duration={500}
                  spy={true}
                  key={index}                    
                  style={{ cursor: 'pointer' }}
                  offset={-30}
                >
                  {x.title}
                </ScrollLink>
              )
            )}
          </nav>
        </div>
        <ScrollLink
          to="cta"
          smooth={true}
          duration={500}
          className={cn("button button-small", styles.button)}
          onClick={() => setVisibleNav(false)}
          style={{ cursor: 'pointer' }}
          offset={-50}
        >
          Download <span className={styles.hide}>&nbsp;Wallet</span>
        </ScrollLink>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
