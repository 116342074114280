import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";
import Icon from "../../../components/Icon";

const Hero = ({ scrollToRef }) => {
  function handleClick() {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className={styles.hero}>
      <Image
        className={styles.blur}
        src="/images/store/blur.png"
        srcDark="/images/store/blur-dark.png"
        alt="blur"
      />
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            <span className={styles.accent}>#1</span> Velas Ecosystem Crypto Wallet
          </h1>
          <div className={styles.text}>
            Stake, buy, and transfer your assets. Manage your NFTs seamlessly.
          </div>
          <div className={styles.btns}>
            <ScrollLink
              className={cn("button", styles.button)}
              to="cta"
              smooth={true}
              duration={500}
              style={{ cursor: 'pointer' }}
              offset={-50}
            >
              Download Wallet
            </ScrollLink>
            <ScrollLink
              className={cn("button-stroke", styles.button)}
              to="whyVelgazm"
              smooth={true}
              duration={500}
              style={{ cursor: 'pointer' }}
              offset={-30}
            >
              Explore
            </ScrollLink>
          </div>
        </ScrollParallax>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              srcSet="/images/store/browser@2x.svg 2x"
              srcSetDark="/images/store/browser-dark@2x.svg 2x"
              src="/images/store/browser.svg"
              srcDark="/images/store/browser-dark.svg"
              alt="Browser"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={900}
          >
            <Image
              srcSet="/images/store/app@2x.svg 2x"
              srcSetDark="/images/store/app-dark@2x.svg 2x"
              src="/images/store/app.svg"
              srcDark="/images/store/app-dark.svg"
              alt="App"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInDown"
            delay={1200}
          >
            <Image
              srcSet="/images/store/menu@2x.svg 2x"
              srcSetDark="/images/store/menu-dark@2x.svg 2x"
              src="/images/store/menu.svg"
              srcDark="/images/store/menu-dark.svg"
              alt="Menu"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
