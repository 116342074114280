import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Features.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    accent: "Full control",
    title: " over your assets",
    image: "/images/store/features-pic-1.svg",
    image2x: "/images/store/features-pic-1@2x.svg",
    imageDark: "/images/store/features-pic-1-dark.svg",
    imageDark2x: "/images/store/features-pic-1-dark@2x.svg",
    content:
      "The Velgazm wallet grants you complete control over your assets, facilitating swift and effortless transactions, purchases, and crypto deposits.",
  },
  {
    accent: "Swap",
    title: " crypto",
    image: "/images/store/features-pic-2.svg",
    image2x: "/images/store/features-pic-2@2x.svg",
    imageDark: "/images/store/features-pic-2-dark.svg",
    imageDark2x: "/images/store/features-pic-2-dark@2x.svg",
    content:
      "Efficiently swap your assets via decentralized exchanges with rapid transaction processing and remarkably low fees.",
  },
  {
    accent: "Stake",
    title: " and Earn",
    image: "/images/store/features-pic-3.svg",
    image2x: "/images/store/features-pic-3@2x.svg",
    imageDark: "/images/store/features-pic-3-dark.svg",
    imageDark2x: "/images/store/features-pic-3-dark@2x.svg",
    content:
      "Choose validators offering the highest APR, delegate your VLX, and earn rewards.",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Features = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Your One-Stop Solution</h2>
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={cn("h3", styles.category)}>
                        <span className={styles.accent}>{x.accent}</span>
                        {x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.wrapper}>
                      <Image
                        srcSet={`${x.image2x} 2x`}
                        src={x.image}
                        srcSetDark={`${x.imageDark2x} 2x`}
                        srcDark={x.imageDark}
                        alt="Features"
                      />
                    </div>
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Features;
