import React from "react";
import cn from "classnames";

const icons = {
  "arrow-down":
    "M12.833 10.39c.428.464.399 1.187-.065 1.615l-4 3.692c-.438.404-1.113.404-1.55 0l-4-3.692c-.464-.428-.493-1.151-.065-1.615s1.151-.493 1.615-.065l2.082 1.922V1.143C6.85.512 7.362 0 7.993 0s1.143.512 1.143 1.143v11.104l2.082-1.922c.464-.428 1.187-.399 1.615.065z",
  "arrow-prev":
    "M5.61 13.697c-.464.428-1.187.399-1.615-.065l-3.692-4c-.404-.438-.404-1.113 0-1.55l3.692-4c.428-.464 1.151-.493 1.615-.065s.493 1.151.065 1.615L3.753 7.714h11.104c.631 0 1.143.512 1.143 1.143S15.488 10 14.857 10H3.753l1.922 2.082c.428.464.399 1.187-.065 1.615z",
  "arrow-next":
    "M10.39 3.303c.464-.428 1.187-.399 1.615.065l3.692 4c.404.438.404 1.113 0 1.55l-3.692 4c-.428.464-1.151.493-1.615.065s-.493-1.151-.065-1.615l1.922-2.082H1.143C.512 9.286 0 8.774 0 8.143S.512 7 1.143 7h11.104l-1.922-2.082c-.428-.464-.399-1.187.065-1.615z",
  instagram:
    "M11.2 0A4.8 4.8 0 0 1 16 4.8h0v6.4a4.8 4.8 0 0 1-4.8 4.8h0-6.4A4.8 4.8 0 0 1 0 11.2h0V4.8A4.8 4.8 0 0 1 4.8 0h0zm0 1.6H4.8a3.2 3.2 0 0 0-3.2 3.2v6.4a3.2 3.2 0 0 0 3.2 3.2h6.4a3.2 3.2 0 0 0 3.2-3.2V4.8a3.2 3.2 0 0 0-3.2-3.2zM8 4a4 4 0 1 1 0 8 4 4 0 1 1 0-8zm0 1.6a2.4 2.4 0 0 0 0 4.8 2.4 2.4 0 0 0 0-4.8zm4-2.4a.8.8 0 1 1 0 1.6.8.8 0 1 1 0-1.6z",
  facebook:
    "M7.967.017a8 8 0 1 1 0 16 8 8 0 1 1 0-16zm0 1.6a6.4 6.4 0 0 0-6.4 6.4c0 2.982 2.039 5.487 4.799 6.198l.001-4.598h-.8a.8.8 0 1 1 0-1.6h.8v-1.6a2.4 2.4 0 0 1 2.4-2.4h.8a.8.8 0 1 1 0 1.6h0-.8a.8.8 0 0 0-.8.8h0v1.6h1.6a.8.8 0 1 1 0 1.6h0-1.6v4.8a6.4 6.4 0 0 0 0-12.8z",
  twitter:
    "M2.121 9.233C1.018 9.236.306 10.492.986 11.444c.973 1.363 2.737 2.456 5.681 2.456 4.563 0 8.226-3.719 7.714-8.113l.751-1.503c.521-1.042-.384-2.227-1.527-1.999l-.993.198c-.263-.139-.535-.244-.768-.32-.454-.148-1.003-.265-1.511-.265-.913 0-1.701.234-2.327.704-.619.464-.968 1.075-1.159 1.635-.088.258-.147.518-.185.766-.354-.111-.715-.261-1.069-.445-.802-.415-1.451-.942-1.817-1.404-.614-.775-1.915-.717-2.371.29-.643 1.42-.467 3.102.111 4.462a6.57 6.57 0 0 0 .754 1.324l-.151.001zm4.546 3.333c-2.576 0-3.907-.933-4.596-1.898-.031-.043 0-.102.054-.103.701-.002 2.139-.035 3.087-.59.049-.029.038-.101-.016-.119-2.211-.743-3.467-3.891-2.575-5.86.02-.045.081-.051.112-.012C3.752 5.271 5.98 6.531 7.919 6.566c.042.001.074-.037.067-.079-.078-.507-.376-3.254 2.347-3.254.65 0 1.618.317 1.974.642a.07.07 0 0 0 .061.017l1.498-.3c.054-.011.098.046.073.095l-.929 1.858c-.006.013-.008.028-.006.042.651 3.658-2.345 6.978-6.337 6.978z",
  linkedin:
    "M12.8.001a3.2 3.2 0 0 1 3.2 3.2h0v9.6a3.2 3.2 0 0 1-3.2 3.2h0-9.6a3.2 3.2 0 0 1-3.2-3.2h0v-9.6a3.2 3.2 0 0 1 3.2-3.2h0zm0 1.6H3.2a1.6 1.6 0 0 0-1.6 1.6v9.6a1.6 1.6 0 0 0 1.6 1.6h9.6a1.6 1.6 0 0 0 1.6-1.6v-9.6a1.6 1.6 0 0 0-1.6-1.6zM4 6.401a.8.8 0 0 1 .8.8h0v4.8a.8.8 0 0 1-1.6 0h0v-4.8a.8.8 0 0 1 .8-.8zm5.6 0a3.2 3.2 0 0 1 3.2 3.2v2.4a.8.8 0 1 1-1.6 0v-2.4a1.6 1.6 0 0 0-3.2 0v2.4a.8.8 0 0 1-1.6 0v-4.8a.8.8 0 0 1 1.529-.33c.486-.298 1.059-.47 1.671-.47zM4 3.201a.8.8 0 0 1 0 1.6.8.8 0 0 1 0-1.6z",
  
  moon:
    "M6.584.005c.576-.116 1.012.512.703 1.012-1.596 2.578-1.209 5.997.97 8.23 1.668 1.608 3.979 2.285 6.273 1.903a.67.67 0 0 1 .694.996c-.279.488-.687.989-1.182 1.484-3.158 3.148-8.268 3.148-11.427 0-3.15-3.15-3.15-8.277-.015-11.411h0l.183-.188A7.44 7.44 0 0 1 6.584.005zM5.433 1.79l-.148.071a6.17 6.17 0 0 0-1.721 1.292h0l-.141.145a6.75 6.75 0 0 0 .14 9.382 6.75 6.75 0 0 0 9.527 0h0l.083-.085-.142-.001a8.57 8.57 0 0 1-5.539-2.216h0l-.183-.172C5.104 7.933 4.424 4.665 5.416 1.835h0l.016-.046.001.001zm9.194.159c.631-.523 1.506.39.891 1.003h0l-.078.086c-.599.728-.573 2.343.078 2.993h0l.054.058c.523.631-.39 1.506-1.003.891h0l-.086-.078c-.728-.599-2.343-.573-2.993.078h0l-.058.054c-.631.523-1.506-.39-.891-1.003h0l.078-.086c.599-.728.573-2.343-.078-2.993h0l-.054-.058c-.523-.631.39-1.506 1.003-.891h0l.086.078c.728.599 2.343.573 2.993-.078h0l.058-.054zm-.905 1.849l-.095.015a4.42 4.42 0 0 1-1.195 0h0l-.096-.015.015.097a4.42 4.42 0 0 1 0 1.195h0l-.015.096.096-.015a4.42 4.42 0 0 1 1.195 0h0l.095.015-.021-.097a4.42 4.42 0 0 1 0-1.195h0l.015-.097.007.001z",
  sun:
    "M8.001 14.001c.338 0 .623.253.662.589l.005.078v.667c0 .353-.275.644-.628.665s-.659-.237-.701-.587l-.005-.078v-.667c0-.368.299-.667.667-.667zm5.142-1.8l.063.055.467.467c.248.249.26.648.028.912s-.63.302-.908.086l-.063-.055-.467-.467c-.248-.249-.26-.648-.028-.912s.63-.302.908-.086zm-9.405.055c.238.238.261.615.055.88l-.055.063-.467.467c-.249.248-.648.26-.912.028s-.302-.63-.086-.908l.055-.063.467-.467c.26-.26.682-.26.943 0h0zM8.001 4a4 4 0 1 1 0 8.001 4 4 0 1 1 0-8.001zm0 1.333a2.67 2.67 0 0 0-2.667 2.667 2.67 2.67 0 0 0 2.667 2.667 2.67 2.67 0 0 0 2.667-2.667 2.67 2.67 0 0 0-2.667-2.667zm-6.667 2c.353 0 .644.275.665.628s-.237.659-.587.701l-.078.005H.667c-.353 0-.644-.275-.665-.628s.237-.659.587-.701l.078-.005h.667zm14.001 0c.353 0 .644.275.665.628s-.237.659-.587.701l-.078.005h-.667c-.353 0-.644-.275-.665-.628s.237-.659.587-.701l.078-.005h.667zM3.209 2.267l.063.055.467.467c.248.249.26.648.028.912s-.63.302-.908.086l-.063-.055-.467-.467c-.248-.249-.26-.648-.028-.912s.63-.302.908-.086zm10.464.055c.238.238.261.615.055.88l-.055.063-.467.467c-.249.248-.648.26-.912.028s-.302-.63-.086-.908l.055-.063.467-.467c.26-.26.682-.26.943 0h0zM8.001 0c.338 0 .623.253.662.589l.005.078v.667c0 .353-.275.644-.628.665s-.659-.237-.701-.587l-.005-.078V.667c0-.368.299-.667.667-.667z",
  share:
  "M7.22816 0.915039H3.68561C2.12051 0.915039 0.851562 2.18398 0.851562 3.74908V12.2512C0.851562 13.8163 2.12051 15.0853 3.68561 15.0853H12.1877C13.7528 15.0853 15.0218 13.8163 15.0218 12.2512C15.0218 10.6422 15.0218 8.70866 15.0218 8.70866C15.0218 8.31756 14.7044 8.00015 14.3133 8.00015C13.9222 8.00015 13.6048 8.31756 13.6048 8.70866V12.2512C13.6048 13.0334 12.9699 13.6682 12.1877 13.6682C9.82839 13.6682 6.04424 13.6682 3.68561 13.6682C2.9027 13.6682 2.26858 13.0334 2.26858 12.2512C2.26858 9.89187 2.26858 6.10771 2.26858 3.74908C2.26858 2.96618 2.9027 2.33206 3.68561 2.33206H7.22816C7.61926 2.33206 7.93667 2.01465 7.93667 1.62355C7.93667 1.23245 7.61926 0.915039 7.22816 0.915039ZM12.6029 2.33206H10.0622C9.6711 2.33206 9.35369 2.01465 9.35369 1.62355C9.35369 1.23245 9.6711 0.915039 10.0622 0.915039H14.3133C14.7044 0.915039 15.0218 1.23245 15.0218 1.62355V5.87461C15.0218 6.26571 14.7044 6.58312 14.3133 6.58312C13.9222 6.58312 13.6048 6.26571 13.6048 5.87461V3.3339L8.43759 8.50106C8.16127 8.77738 7.71207 8.77738 7.43575 8.50106C7.15872 8.22474 7.15872 7.77555 7.43575 7.49923L12.6029 2.33206Z",
  arrowR:
  "M8.62505 8.6169L2.60526 14.6365C2.22233 15.0196 1.60147 15.0196 1.21872 14.6365C0.835947 14.2537 0.835947 13.6329 1.21872 13.2501L6.54531 7.92373L1.21888 2.5975C0.836101 2.21456 0.836101 1.5938 1.21888 1.21103C1.60165 0.828095 2.22248 0.828095 2.60541 1.21103L8.6252 7.23071C8.81659 7.42219 8.91218 7.67288 8.91218 7.9237C8.91218 8.17464 8.8164 8.42551 8.62505 8.6169Z",  
};

const Icon = (props) => {
  const size = props.size ? props.size : 16;
  return (
    <svg
      className={cn(props.className)}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path d={icons[props.name]}></path>
    </svg>
  );
};

export default Icon;
