import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Cta.module.sass";


const Cta = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("cta container", styles.cta)}>
            <img className={cn("device", styles.device)}
                  src="/images/store/device-mockups.png"
                  alt="device-mockups"
                />
            <div className={cn("stage", styles.stage)}>
                <img
                  src="/images/store/logo3d.png"
                  alt="logo"
                  />
            </div>
            <h2 className={cn("h2", styles.title)}>
            Download <br></br> Velgazm wallet
            </h2>
            <ul className={cn("market", styles.market)}>
                <li><a href="https://wallet.velgazm.com/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/web.svg" alt="Web version" />
                  <p className={cn("button-link button-small", styles.button)}>Web version</p>
                </a></li>
                <li><a href="https://chromewebstore.google.com/search/Velgazm" target="_blank" rel="noopener noreferrer">
                    <img src="/images/store/chrome.svg" alt="Linux" />
                    <p className={cn("button-link button-small", styles.button)}>Chrome</p>
                  </a></li>
                  <li><a href="/" target="_blank" rel="noopener noreferrer">
                    <img src="/images/store/safari.svg" alt="Linux" />
                    <p className={cn("button-link button-small", styles.button)}>Safari</p>
                  </a></li>
                  <li><a href="/" target="_blank" rel="noopener noreferrer">
                    <img src="/images/store/opera.svg" alt="Linux" />
                    <p className={cn("button-link button-small", styles.button)}>Opera</p>
                  </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/appstore.svg" alt="App Store" />
                  <p className={cn("button-link button-small", styles.button)}>App Store</p>
                </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/googleplay.svg" alt="Google Play" />
                  <p className={cn("button-link button-small", styles.button)}>Google Play</p>
                </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/apk.svg" alt="APK" />
                  <p className={cn("button-link button-small", styles.button)}>Android APK</p>
                </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/macos.svg" alt="MacOS" />
                  <p className={cn("button-link button-small", styles.button)}>MacOS</p>
                </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/windows.svg" alt="Windows" />
                  <p className={cn("button-link button-small", styles.button)}>Windows</p>
                </a></li>
                <li><a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/store/linux.svg" alt="Linux" />
                  <p className={cn("button-link button-small", styles.button)}>Linux</p>
                </a></li>
                
            </ul>
            
          </div>
      </div>
    </div>
  );
};

export default Cta;
