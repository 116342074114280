import React from "react";
import Header from "../../components/Header";
import Hero from "./Hero";
import WhyVelgazm from "./WhyVelgazm";
import Features from "./Features";
import Nft from "./Nft";
import Dapps from "./Dapps";
import Accounts from "./Accounts";
import Token from "./Token";
import CTA from "./CTA";
import { Element } from "react-scroll";

const Main = () => {
  return (
    <>
      <Header />
      <Element name="hero">
        <Hero />
      </Element>
      <Element name="cta">
        <CTA className="section-border-top with-image" />
      </Element>
      <Element name="whyVelgazm">
        <WhyVelgazm className="section" />
      </Element>
      <Element name="features">
        <Features />
      </Element>
      {/* <Element name="nft">
        <Nft className="section-border-top" />
      </Element>
      <Element name="dapps">
        <Dapps className="section-border-top" />
      </Element> */}
      <Element name="accounts">
        <Accounts />
      </Element>
      <Element name="token">
        <Token />
      </Element>
      <Element name="cta2">
        <CTA className="section-border-top with-image" />
      </Element>
    </>
  );
};

export default Main;
