import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Explore",
    to: "whyVelgazm",
  },
  {
    title: "Features",
    to: "features",
  },
  {
    title: "Support",
    url: "https://t.me/velascommunity",
  },
  {
    title: "GitHub",
    url: "https://github.com/velas",
  },
];

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/velgazmWallet.net/",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/velgazmWallet",
  },
  {
    title: "linkedin",
    size: "16",
    url: "https://www.instagram.com/velgazmWalletnet/",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <Image
                  className={styles.pic}
                  src="/images/logo-dark.svg"
                  srcDark="/images/logo-light.svg"
                  alt="Logo"
                />
              </Link>
            </div>
          </div>

          <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div className={styles.menu}>
                {menu.map((x, index) =>
                  x.url ? (
                    <a
                      className={styles.link}
                      href={x.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      style={{ cursor: 'pointer' }}
                    >
                      {x.title}
                    </a>
                  ) : (
                    <ScrollLink
                      className={styles.link}
                      activeClassName={styles.active}
                      to={x.to}
                      smooth={true}
                      duration={500}
                      spy={true}
                      key={index}
                      style={{ cursor: 'pointer' }}
                      offset={-50}
                    >
                      {x.title}
                    </ScrollLink>
                  )
                )}
              </div>
            </div>
          </div>

          <div className={styles.col}>
            {/* <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size={x.size} />
                </a>
              ))}
            </div> */}
            <Theme className={styles.theme} />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2024. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
